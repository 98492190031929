import React from 'react';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Input,
  Button,
  Icon,
  FormGroup,
  Label,
} from 'design-react-kit/dist/design-react-kit';
import Checkbox from '@italia/components/Checkbox';

const messages = defineMessages({
  add: {
    id: 'add',
    defaultMessage: 'Aggiungi',
  },
  addPeople: {
    id: 'addPeople',
    defaultMessage: 'Aggiungi persone per cui richiedi il servizio',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Elimina',
  },
  selectPlaceholder: {
    id: 'selectPlaceholder',
    defaultMessage: 'Per chi compili questa richiesta?',
  },
  richiedo_per_altri: {
    id: 'richiedoPerAltri',
    defaultMessage: 'Richiedo per altri',
  },
  nome: {
    id: 'nome',
    defaultMessage: 'Nome',
  },
  cognome: {
    id: 'Cognome',
    defineMessages: 'Cognome',
  },
  data_nascita: {
    id: 'dataNascita',
    defaultMessage: 'Data di nascita',
  },
  luogo_nascita: {
    id: 'luogoNascita',
    defaultMessage: 'Luogo di nascita',
  },
  codice_fiscale: { id: 'codiceFiscale', defaultMessage: 'Codice fiscale' },
  numero_documento: {
    id: 'numero_documento',
    defaultMessage: 'Numero del documento di riconoscimento',
  },
});

const familyFields = [
  'nome',
  'cognome',
  'data_nascita',
  'luogo_nascita',
  'codice_fiscale',
  'numero_documento',
];

const matchCF = (v) =>
  v.match(
    /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
  );

export const isValid = (formData, name) => {
  let valid = true;

  if (!formData[name]?.value?.richiedo_per_altri) return true;
  if (formData[name]?.value?.richiedenti?.length === 0) return false;

  formData[name].value.richiedenti.forEach((m) => {
    familyFields.forEach((f) => {
      if (m[f]?.length <= 0) valid = false;
      else if (f === 'codice_fiscale' && !matchCF(m[f])) valid = false;
    });
  });

  return valid;
};

const RichiedentiWidget = ({
  id,
  name,
  title,
  description,
  onChange,
  formHasErrors = false,
  value,
  invalid,
  className,
}) => {
  const intl = useIntl();
  const required = true; // it needs to be required
  const [activeAccIndex, setActiveAccIndex] = React.useState(null);

  React.useEffect(() => {
    if (value === undefined) {
      setTimeout(
        () => onChange(name, { richiedo_per_altri: false, richiedenti: [] }),
        0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const valid =
    invalid === null ||
    invalid === undefined ||
    invalid === 'false' ||
    invalid === false;

  return (
    <div
      className={cx('form-group bootstrap-checkbox-radio-wrapper', {
        'is-invalid': !valid,
      })}
    >
      <label htmlFor={`field-${id}`} className="active">
        {title}
      </label>
      {description && <small className="help px-2">{description}</small>}
      <div className="form-richiedenti-widget px-2 my-3" id={`field-${id}`}>
        <FormGroup check tag="div">
          <Checkbox
            id="richiedo_per_altri"
            className={cx('form-control', {
              'is-invalid': !valid,
            })}
            checked={!!value?.richiedo_per_altri}
            onChange={(e) => {
              onChange(name, {
                richiedo_per_altri: e.target.checked,
                richiedenti: value?.richiedenti ?? [],
              });
            }}
          />
          <Label
            check
            htmlFor="richiedo_per_altri"
            tag="label"
            widths={['xs', 'sm', 'md', 'lg', 'xl']}
          >
            {intl.formatMessage(messages.richiedo_per_altri)}
          </Label>
        </FormGroup>

        {value?.richiedo_per_altri && (
          <div
            className={cx('px-2', className)}
            style={
              !valid && formHasErrors
                ? { borderLeft: '5px solid #d9364f' }
                : null
            }
          >
            {value.richiedenti?.length > 0 ? (
              <Accordion className="my-4">
                {value.richiedenti.map((r, i) => (
                  <div key={`richiedente-${i}`}>
                    <AccordionHeader
                      active={activeAccIndex === i}
                      onToggle={(e) => {
                        e.preventDefault();
                        activeAccIndex === i
                          ? setActiveAccIndex(null)
                          : setActiveAccIndex(i);
                      }}
                      tag="button"
                      aria-controls={`richiedente-${i}`}
                      id={`header-richiedente-${i}`}
                    >
                      {`Richiedente ${i + 1}${r.nome || r.cognome ? ':' : ''} ${
                        r.nome || ''
                      } ${r.cognome || ''}`}
                    </AccordionHeader>
                    <AccordionBody
                      active={activeAccIndex === i}
                      id={`richiedente-${i}`}
                      aria-labelledby={`field-${id} header-richiedente-${i}`}
                      aria-hidden={!activeAccIndex === i}
                      role="region"
                    >
                      <div className="my-4 mx-2">
                        {familyFields.map((field) => (
                          <Input
                            key={`richiedente-${i}-${field}`}
                            type={field === 'data_nascita' ? 'date' : 'text'}
                            id={`richiedente-${i}-${field}`}
                            name={field}
                            label={
                              intl.formatMessage(messages[field]) +
                              (required ? ' *' : '')
                            }
                            invalid={
                              field === 'codice_iscale' && r[field]?.length
                                ? !matchCF(r[field])
                                : false
                            }
                            onChange={(e) => {
                              let richiedenti = [...value.richiedenti];
                              richiedenti[i][field] =
                                field === 'codice_fiscale'
                                  ? e.target.value.toUpperCase()
                                  : e.target.value;
                              onChange(name, { ...value, richiedenti });
                            }}
                            value={r[field] ?? ''}
                          />
                        ))}
                        <Button
                          primary
                          outline
                          onClick={() => {
                            let richiedenti = [...value.richiedenti];
                            richiedenti.splice(i, 1);
                            onChange(name, { ...value, richiedenti });
                          }}
                          title={intl.formatMessage(messages.delete)}
                        >
                          <Icon
                            color="primary"
                            icon="it-delete"
                            padding={false}
                            size="sm"
                          />
                        </Button>
                      </div>
                    </AccordionBody>
                  </div>
                ))}
              </Accordion>
            ) : (
              <p className="my-3 px-2">
                {intl.formatMessage(messages.addPeople)}
              </p>
            )}
            <Button
              primary
              outline
              className="mx-2"
              onClick={() => {
                onChange(name, {
                  ...value,
                  richiedenti: [
                    ...value.richiedenti,
                    familyFields.reduce(
                      (acc, val) => ({
                        ...acc,
                        [val]: '',
                      }),
                      {},
                    ),
                  ],
                });
                setActiveAccIndex(value.richiedenti.length);
              }}
              title={intl.formatMessage(messages.add)}
            >
              <Icon color="primary" icon="it-plus" padding={false} size="sm" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RichiedentiWidget;
