import React from 'react';
//eslint-disable-next-line import/no-unresolved
import LogoHeader from '@package/components/Layout/img/logo.png?width=55';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const Logo = () => (
  <img
    src={LogoHeader}
    width="55"
    height="82"
    className="icon"
    alt="Logo Unione dei Comuni Val di Bisenzio"
  />
);

export default Logo;
